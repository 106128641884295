import quoteClient from 'api/quoteClient';
import { graphql, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import InterstitialPage from 'components/InterstitialPage';
import { trackEvent } from 'helpers/eventTracking';
import { getQueryParam } from 'helpers/getQueryParam';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import usePageTitle from 'helpers/usePageTitle';
import { usePageTracking } from 'helpers/usePageTracking';
import { ErrorType, UPDATE_ERROR } from 'state/error/actions';
import { UPDATE_QUOTE } from 'state/quote/quote';
import useDispatch from 'state/useDispatch';
import { CsMeta } from 'types/contentStack';

export type AggOptions = { Legal?: string; CourtesyCar?: string; Breakdown?: string };

type AggregatorProps = {
  data: {
    csInterstitialPage: {
      aggregator_quote_summary: {
        meta: CsMeta;
        heading: string;
        subheading: string;
      };
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csInterstitialPage {
      aggregator_quote_summary {
        meta {
          meta_title
        }
        heading
        subheading
      }
    }
  }
`;

const Aggregator: React.FC<AggregatorProps> = ({
  data: {
    csInterstitialPage: {
      aggregator_quote_summary: { meta, heading, subheading },
    },
  },
  location,
}) => {
  const { requestHandler } = useApiRequestHandler();
  const dispatch = useDispatch();
  const [tier, setTier] = useState<string | null>(null);

  useEffect(() => {
    setTier(getQueryParam(location, 'Tier'));
  }, [location]);

  const fullPageTitle = usePageTitle(meta.meta_title);
  const aggName = getQueryParam(location, 'Agg');

  useEffect(() => {
    const event = {
      event: 'aggregator',
      referrer: document.referrer,
      pageTitle: fullPageTitle,
      vpvPath: location.pathname,
      landingPage: location.href,
      activityGuid: getQueryParam(location, 'activityGuid'),
      clickThroughId: getQueryParam(location, 'ClickthroughId'),
      agg: aggName,
    };

    trackEvent(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  usePageTracking(meta.meta_title, true, undefined, undefined, aggName ?? undefined);

  useEffect(() => {
    const ref = getQueryParam(location, 'ref');

    if (!ref) {
      dispatch({
        type: UPDATE_ERROR,
        errorType: ErrorType.NOT_FOUND,
        statusCode: 404,
      });
      return;
    }

    const params = {
      Tier: getQueryParam(location, 'Tier') ?? undefined,
      Legal: getQueryParam(location, 'Legal') ?? undefined,
      CourtesyCar: getQueryParam(location, 'CourtesyCar') ?? undefined,
      Breakdown: getQueryParam(location, 'Breakdown') ?? undefined,
    };

    requestHandler(async () => {
      const quote = await quoteClient.getAggregatorQuote(ref, params);
      dispatch({ type: UPDATE_QUOTE, quote });
      navigate(quoteAndBuyRoutes.quoteSummary(quote.quoteNumber));
    });
  }, [dispatch, location, requestHandler]);

  return (
    <InterstitialPage
      heading={heading}
      subheading={subheading}
      meta={meta}
      tier={tier}
      data-cy="AggregatorInterstitialPage"
    />
  );
};
export default Aggregator;
